import {
	anim,
	FONT_COLOR,
	FONT_SIZE,
	LabeledInput,
	PaddedContainer,
	SPACING,
	STATUS,
	StatusLabel,
	Text,
} from "@disco/disco_core";
import React, { useEffect, useState } from "react";

import "./lead-offers-editor.css";
import {
	getLeadOfferType,
	LEAD_OFFER_TYPE,
} from "../modals/LeadOffersTypeSelectorModal";

import "./lead-offers-editor-fields.css";
import LeadOffersEditorUTMBanner from "./LeadOffersEditorUTMBanner";
import isValidUrl from "@disco/disco_core/src/utils/isValidUrl";

const LeadOffersEditorFieldTooltip = ({ title, children }) => {
	return (
		<>
			<Text size={FONT_SIZE.BODY} thick>
				{title}
			</Text>
			<Text
				size={FONT_SIZE.LABEL}
				color={FONT_COLOR.MID}
				marginTop={SPACING.MICRO}
			>
				{children}
			</Text>
		</>
	);
};

export const LEAD_OFFER_FIELD_VALIDATOR = {
	heading: ({ inp }) => {
		if (!inp || typeof inp !== "string" || inp.trim().length === 0)
			return [false, "Please enter a value"];
		return [true, ""];
	},
	subheading: ({ inp }) => {
		if (!inp || typeof inp !== "string" || inp.trim().length === 0)
			return [false, "Please enter details"];
		if (inp.indexOf("!") !== -1)
			return [
				false,
				"Please avoid using exclamation points. Stick to plain text for clarity.",
			];
		const splitInp = inp.trim().split(" ");
		let hasAllCaps = false;
		splitInp.forEach((inpWord) => {
			const isLetteredWord = /^[A-Za-z]+$/.test(inpWord);
			if (inpWord === inpWord.toUpperCase() && isLetteredWord) {
				hasAllCaps = true;
			}
		});
		if (hasAllCaps)
			return [
				false,
				"Please avoid using all caps. Use sentence or title case for better readability.",
			];
		return [true, ""];
	},
	landing_page_url: ({ inp }) => {
		if (!inp || typeof inp !== "string" || inp.length === 0)
			return [true, ""];
		if (!isValidUrl(inp)) return [false, "Please enter a valid URL"];
		return [true, ""];
	},
	code: ({ inp, offerType }) => {
		if (offerType === LEAD_OFFER_TYPE.NO_PROMO_CODE) return [true, ""];
		if (!inp || typeof inp !== "string" || inp.trim().length === 0)
			return [false, "Please enter a promo code"];
		return [true, ""];
	},
	disclosure: () => {
		return [true, ""];
	},
};

const LEAD_OFFER_FIELDS = {
	BASE: {
		heading: {
			order: 0,
			label: "Value",
			labelProps: { required: true },
			placeholder: "20% off",
			maxLength: 20,
			tooltip: {
				children: (
					<LeadOffersEditorFieldTooltip title="Value">
						Enter only the promotional amount (e.g., 20% off, $20
						off, Free shipping, Free gift with purchase, Buy one get
						one free).
					</LeadOffersEditorFieldTooltip>
				),
				noBorder: true,
				persistent: true,
			},
		},
		subheading: {
			order: 1,
			maxLength: 30,
			label: "Details",
			labelProps: { required: true },
			placeholder: "On your entire purchase",
			tooltip: {
				children: (
					<LeadOffersEditorFieldTooltip title="Details">
						Specify the purchase requirements for this offer. Only
						include conditions like:
						<br />
						<br />
						<PaddedContainer marginLeft={SPACING.SMALL}>
							<ul>
								<li>
									Minimum order value (e.g., &apos;Orders over
									$50&apos;)
								</li>
								<li>
									Purchase timing (e.g., &apos;On your next
									purchase&apos;)
								</li>
								<li>
									Applicable products (e.g., &apos;On products
									A, B, and C&apos;)
								</li>
							</ul>
						</PaddedContainer>
						<br />
						Do not use all caps, exclamation points, or include a
						promo code.
					</LeadOffersEditorFieldTooltip>
				),
				noBorder: true,
				persistent: true,
			},
		},
		landing_page_url: {
			order: 3,
			label: "Landing Page URL",
			placeholder: "https://www.yourbrand.com/special-offer",
			tooltip: {
				children: (
					<LeadOffersEditorFieldTooltip title="Landing Page URL">
						Enter the URL where customers land after clicking your
						offer. If left blank, shopper will be directed to your
						homepage.
					</LeadOffersEditorFieldTooltip>
				),
				noBorder: true,
				persistent: true,
			},
			children: ({ focusedField }) =>
				focusedField === "landing_page_url" && (
					<LeadOffersEditorUTMBanner
						marginTop={SPACING.SMALL}
						motionElement
						variants={anim.variantFactory({ y: -12, opacity: 0.3 })}
					/>
				),
		},
		disclosure: {
			order: 4,
			label: "Disclosure",
			textarea: true,
			placeholder: "Cannot be combined with other promotional offers",
			maxLength: 255,
			tooltip: {
				children: (
					<LeadOffersEditorFieldTooltip title="Disclosure">
						If terms and conditions are required for the offer
						please provide them here. They will be included in a
						follow-up email delivered to the customer immediately
						upon claiming the offer.
					</LeadOffersEditorFieldTooltip>
				),
				noBorder: true,
				persistent: true,
			},
		},
	},
	[LEAD_OFFER_TYPE.PROMO_CODE]: {
		code: {
			order: 2,
			label: "Promo Code",
			maxLength: 25,
			placeholder: "SHOP30DISCO",
			labelProps: { required: true },
			tooltip: {
				children: (
					<LeadOffersEditorFieldTooltip title="Promo Code">
						Codes should be simple, memorable, and no spaces
					</LeadOffersEditorFieldTooltip>
				),
				noBorder: true,
				persistent: true,
			},
			children: (
				<StatusLabel
					marginTop={SPACING.SMALL}
					vPadding={SPACING.SMALL}
					hPadding={SPACING.REGULAR}
					noBorder
					noShadow
					type={STATUS.INFO}
				>
					<Text>
						Please ensure the Customer Eligibility is set to{" "}
						<b>All Customers</b> in the offer code settings in
						Shopify.
					</Text>
				</StatusLabel>
			),
		},
	},
	[LEAD_OFFER_TYPE.NO_PROMO_CODE]: {},
};

const LeadOffersEditorFields = ({
	offer,
	setOffer,
	errors,
	disabled = false,
}) => {
	const offerType = getLeadOfferType(offer);

	const [renderableFields, setRenderableFields] = useState([]);

	useEffect(() => {
		let baseFieldsDict = LEAD_OFFER_FIELDS.BASE;
		baseFieldsDict = { ...baseFieldsDict, ...LEAD_OFFER_FIELDS[offerType] };
		setRenderableFields(
			Object.entries(baseFieldsDict)
				.map(([remoteKey, props]) => ({ ...props, name: remoteKey }))
				.sort((a, b) => a.order - b.order)
		);
	}, [offerType]);

	const handleChange = ({ target: { name, value } }) =>
		setOffer((offer) => ({ ...offer, [name]: value }));

	const [focusedField, setFocusedField] = useState(null);

	const handleFocus = ({ target: { name } }) => setFocusedField(name);

	return renderableFields.map(({ name, children, ...rest }) => (
		<LabeledInput
			value={offer?.[name]}
			name={name}
			key={name}
			onChange={handleChange}
			className="lead-offers-editor-field"
			onFocus={handleFocus}
			disabled={disabled}
			{...rest}
		>
			{errors && errors?.[name] && (
				<StatusLabel
					noShadow
					noBorder
					type="error"
					marginTop={SPACING.SMALL}
				>
					{errors[name]}
				</StatusLabel>
			)}
			{typeof children === "function"
				? children({ focusedField })
				: children}
		</LabeledInput>
	));
};

export default LeadOffersEditorFields;
