import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import BrandOptOutModalSelector from "../BrandOptOutModalSelector";
import ProductsContainer from "../ProductsContainer";
import SubCategoryOptOutModalSelector from "../SubCategoryOptOutModalSelector";
import WidgetModule, { WidgetModuleToggler } from "./WidgetModule";
import NewEditView from "../NewEditView";
import {
	PREVIEW_TYPES_IDX,
	SAVE_DESCRIPTION_URL,
	SAVE_NETWORK_URL,
	SAVE_PUBLISHER_URL,
} from "../../conf";

import {
	PLATFORM_CROSS_SELL_TOGGLE,
	PLATFORM_VIEW_CROSS_SELL_THANKYOU,
} from "../../events";
import useIdentity from "../../hooks/useIdentity";

import "./cross-sell-module.css";

import {
	Asset,
	Button,
	CheckIcon,
	CheckboxElement,
	ChevronDownIcon,
	Dropdown,
	FONT_COLOR,
	InformationTooltip,
	Label,
	Loader,
	PaddedContainer,
	RemoteButton,
	SPACING,
	STATUS,
	StatusLabel,
	Text,
	isFunction,
	parseError,
	useResource,
} from "@disco/disco_core";
import { updateUser } from "../../utils/userManipulators";
import useViewTrack from "../../hooks/useViewTrack";
import ExtensionSetupInstructions from "../ExtensionSetupInstructions";
import hasMigratedToExtensions from "../../utils/hasMigratedToExtensions";
import LeadOffersContainer from "../LeadOffersContainer";
import BannerEditView from "../BannerEditView";

const CrossSellModuleSettings = memo(({ editMode, triggerRefresh }) => {
	const { user, setUser } = useIdentity();
	const shouldShowExtensionInstructions = hasMigratedToExtensions({
		user,
		considerOverride: true,
	});

	return (
		<>
			{shouldShowExtensionInstructions && (
				<ExtensionSetupInstructions marginBottom={SPACING.REGULAR} />
			)}
			<WidgetModuleToggler
				user={user}
				setUser={setUser}
				moduleName={user.isNewIa ? "DiscoFeed" : "Cross-Sell"}
				remoteKey="visible_on_network"
				toggleUrl={SAVE_NETWORK_URL}
				onToggle={triggerRefresh}
				event={PLATFORM_CROSS_SELL_TOGGLE}
				designSystem
			/>
			<PaddedContainer marginTop={SPACING.MEDIUM}>
				<NewEditView
					draftable
					user={user}
					setUser={setUser}
					name="Short Description"
					className="cross-sell-module-short-desc"
					placeholder="Briefly describe your shop for potential shoppers"
					url={SAVE_DESCRIPTION_URL}
					remoteKey="single_liner"
					textarea
					maxLength={55}
					tooltipTitle="What kind of description?"
					tooltipText="Keep it brief and engaging. This is how shoppers will learn about your shop"
				/>
			</PaddedContainer>
			<PaddedContainer
				flexContent
				marginTop={SPACING.MEDIUM}
				alignContentCenter
				marginBottom={SPACING.TINY}
			>
				<Label fitContent marginRight={SPACING.TINY}>
					Banner Image
				</Label>
				<InformationTooltip persistent activatorSize={20}>
					<Text size={"body"} thick>
						Banner Image
					</Text>
					<Text size={"label"} color="mid" marginTop={SPACING.MICRO}>
						Use high-quality, text-free images that reflect your
						brand and showcase your products. For best results,
						upload a 16:9 image that&apos;s at least 1064x600
						pixels.
					</Text>
				</InformationTooltip>
			</PaddedContainer>
			<BannerEditView
				onDone={triggerRefresh}
				className="cross-sell-module-banner"
			/>
			<PaddedContainer vPadding={SPACING.REGULAR} />
			<SubCategoryOptOutModalSelector
				user={user}
				setUser={setUser}
				editMode={editMode}
				onClose={triggerRefresh}
			/>
			<BrandOptOutModalSelector
				user={user}
				setUser={setUser}
				editMode={editMode}
				onClose={triggerRefresh}
			/>
		</>
	);
});

const CrossSellModuleOrdering = memo(
	({ user, setUser, editMode, setEditMode, triggerRefresh }) => {
		return (
			<ProductsContainer
				crossSellOnly={true}
				user={user}
				setUser={setUser}
				setRefresh={triggerRefresh}
				editMode={editMode}
				setEditMode={setEditMode}
			/>
		);
	}
);

const ToggleVisibility = () => {
	const { user, setUser } = useIdentity();
	const value = useMemo(
		() => user.publisher?.visible_as_publisher,
		[user.publisher]
	);
	const payload = useMemo(() => ({ visible_as_publisher: !value }), [value]);
	const handleDone = useCallback(
		({ data }) =>
			updateUser({ setUser, data, key: "visible_as_publisher" }),
		[setUser]
	);

	return (
		<RemoteButton
			url={SAVE_PUBLISHER_URL}
			payload={payload}
			vPadding={SPACING.TINY}
			hPadding={SPACING.REGULAR}
			onDone={handleDone}
		>
			{value ? (
				<>
					<Asset size={15} marginRight={SPACING.TINY}>
						<CheckIcon />
					</Asset>
					Published
				</>
			) : (
				"Publish"
			)}
		</RemoteButton>
	);
};

const CrossSellModule = memo(() => {
	const { user, setUser } = useIdentity();
	const [editMode, setEditMode] = useState(user.publisher.under_review);
	const [checkedOptions, setCheckedOptions] = useState({});
	const [success, setSuccess] = useState(false);
	const closeRef = useRef(null);

	const publishOptions = useMemo(
		() => ({
			visible_as_publisher: {
				heading: `Publish the ${
					user.isNewIa ? "DiscoFeed" : "Disco Carousel"
				} on my page`,
				text: user.publisher.visible_as_publisher
					? `Your  ${
							user.isNewIa ? "DiscoFeed" : "Disco Carousel"
					  } is live`
					: `Your  ${
							user.isNewIa ? "DiscoFeed" : "Disco Carousel"
					  } is not live yet`,
				checked: user.publisher.visible_as_publisher,
			},
			notify: {
				heading: user?.publisher?.verification_notification_sent
					? "Your account has been submitted for review"
					: "Submit my account & products for Disco to review",
				text: user.publisher.verified
					? "Your products are live"
					: "Your products are not live yet",
				checked: user.publisher.verification_notification_sent,
				disableCheck: user.publisher.verification_notification_sent,
			},
		}),
		[user]
	);

	const [{ loading, error, data }, save, reset] = useResource(
		{
			url: SAVE_PUBLISHER_URL,
			method: "PUT",
			data: publishOptions
				? Object.keys(publishOptions).reduce(
						(data, key) => ({
							...data,
							[key]: checkedOptions[key] ?? false,
						}),
						{}
				  )
				: {},
		},
		false
	);

	useEffect(() => {
		setCheckedOptions(
			publishOptions
				? Object.entries(publishOptions).reduce(
						(data, [key, option]) => ({
							...data,
							[key]: option.checked ?? false,
						}),
						{}
				  )
				: {}
		);
	}, [publishOptions]);

	useEffect(() => {
		if (!data) return;
		if (isFunction(closeRef.current)) {
			closeRef.current();
		}
		if (typeof setUser === "function") {
			setUser((user) => ({
				...user,
				publisher: {
					...user.publisher,
					...data,
				},
			}));
		}
		reset();
		setSuccess(data.visible_as_publisher);

		setTimeout(() => {
			setSuccess(false);
		}, 2000);
	}, [data, reset, checkedOptions, setUser]);

	const handleChange = useCallback((name) => {
		setCheckedOptions((options) => ({
			...options,
			[name]: !options[name],
		}));
	}, []);

	const renderOptions = useCallback(
		({ options, close }) => {
			if (!closeRef.current) {
				closeRef.current = close;
			}
			return (
				<PaddedContainer vPadding={SPACING.REGULAR} tightTop>
					{Object.entries(options).map(([key, option]) => (
						<PaddedContainer
							key={key}
							onClick={() => {
								if (loading || option?.disableCheck) return;
								handleChange(key);
							}}
							className="cross-sell-module-header-checkbox"
						>
							<CheckboxElement
								title={option.heading}
								subtitle={option.text}
								checked={checkedOptions[key] ?? false}
								name={key}
								disabled={loading || option?.disableCheck}
							></CheckboxElement>
						</PaddedContainer>
					))}
					<PaddedContainer centerContent>
						<Button onClick={save} disabled={loading}>
							{loading ? <Loader small light /> : "Publish"}
						</Button>
					</PaddedContainer>
					{error && (
						<PaddedContainer
							hPadding={SPACING.REGULAR}
							marginTop={SPACING.REGULAR}
						>
							<StatusLabel type={STATUS.ERROR}>
								{parseError(error)}
							</StatusLabel>
						</PaddedContainer>
					)}
				</PaddedContainer>
			);
		},
		[checkedOptions, error, handleChange, loading, save]
	);

	useViewTrack(PLATFORM_VIEW_CROSS_SELL_THANKYOU);

	const renderSettings = useCallback(
		(props) => <CrossSellModuleSettings editMode={editMode} {...props} />,
		[editMode]
	);

	const renderOrdering = useCallback(
		(props) => (
			<CrossSellModuleOrdering
				user={user}
				setUser={setUser}
				editMode={editMode}
				setEditMode={setEditMode}
				{...props}
			/>
		),
		[user, setUser, editMode]
	);

	const renderLeadGenOffers = useCallback(
		(props) => <LeadOffersContainer {...props} />,
		[]
	);

	const tabOptions = useMemo(() => {
		let options = [
			{
				name: "Manage",
				component: renderSettings,
			},
			{
				name: "Offers and Leads",
				component: renderLeadGenOffers,
			},
			{
				name: "Product Ordering",
				component: renderOrdering,
				showCrossSellProducts: true,
			},
		];
		return options;
	}, [renderSettings, renderOrdering, renderLeadGenOffers]);

	useEffect(() => {
		if (user.publisher.under_review) {
			setEditMode(true);
		}
	}, [user.publisher.under_review]);

	return (
		<>
			<section
				className="cross-sell-module-wrapper"
				data-testid="cross-sell-module"
			>
				<header className="cross-sell-module-toggler-header">
					{user.publisher.verified ? (
						<ToggleVisibility />
					) : (
						<Dropdown
							className="cross-sell-module-header-publish"
							options={publishOptions}
							renderOptions={renderOptions}
							activator={
								<Button
									className="cross-sell-module-header-publish-btn"
									vPadding={SPACING.TINY}
									hPadding={SPACING.REGULAR}
								>
									{success && (
										<Asset
											size={16}
											marginRight={SPACING.TINY}
										>
											<CheckIcon />
										</Asset>
									)}
									{success ? "Published" : "Publish"}
									<Asset color={FONT_COLOR.WHITE} size={20}>
										<ChevronDownIcon />
									</Asset>
								</Button>
							}
							placeholder=""
							value={""}
							multiple={false}
							hideToggleIcon
						/>
					)}
				</header>

				<WidgetModule
					user={user}
					setUser={setUser}
					tabOptions={tabOptions}
					heading={
						user?.isNewIa ? "DiscoFeed" : "Cross-Sell Partners"
					}
					className="new-cross-sell-module"
					remoteKey="visible_on_network"
					toggleUrl={SAVE_NETWORK_URL}
					previewType={PREVIEW_TYPES_IDX.CROSS_SELL}
				/>
			</section>
		</>
	);
});

export default CrossSellModule;
