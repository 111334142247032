import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	anim,
	Asset,
	Card,
	DropdownSelect,
	FONT_SIZE,
	Loader,
	MoreHorizontalIcon,
	PaddedContainer,
	parseError,
	SPACING,
	StatusLabel,
	Text,
	useModal,
	useResource,
	useTimer,
} from "@disco/disco_core";

import { SAVE_LEAD_OFFERS_URL } from "../../conf";
import windowRedirect from "../../utils/windowRedirect";

import "./lead-offers-inline-view-offer.css";
import {
	LEAD_OFFER_TYPE,
	LEAD_OFFER_TYPE_KEY,
} from "../modals/LeadOffersTypeSelectorModal";
import { AnimatePresence } from "framer-motion";

const LeadOffersInlineViewOfferSettings = ({
	offer,
	onEdit,
	onDelete,
	onDeleteError,
	...rest
}) => {
	const [
		{ loading: deleteLoading, data: deleteData, error: deleteError },
		deleteOffer,
		reset,
	] = useResource(
		{
			url: SAVE_LEAD_OFFERS_URL(offer),
			method: "DELETE",
			data: null,
		},
		false
	);

	useEffect(() => {
		if (!deleteError) return;
		onDeleteError(deleteError);
	}, [deleteError, onDeleteError]);

	useEffect(() => {
		if (!deleteData) return;
		onDelete({ offer });
		reset();
	}, [deleteData, offer, reset, onDelete]);

	const options = useMemo(
		() => ({
			deleteOffer: {},
		}),
		[]
	);

	const renderOptions = useCallback(
		({ close }) => {
			const handleClick = () => {
				close();
				deleteOffer();
			};
			return (
				<Text
					color={"var(--disco-red-mid)"}
					thick
					centerContent
					size={FONT_SIZE.BODY}
					onClick={handleClick}
					clickable
				>
					Delete Offer
				</Text>
			);
		},
		[deleteOffer]
	);

	const renderActivator = useMemo(
		() => (
			<PaddedContainer marginLeft={SPACING.MEDIUM}>
				{deleteLoading ? (
					<Loader small />
				) : (
					<Asset size={25}>
						<MoreHorizontalIcon />
					</Asset>
				)}
			</PaddedContainer>
		),
		[deleteLoading]
	);

	const handleEditClick = () => {
		if (typeof onEdit !== "function") return;
		onEdit({ offer });
	};

	return (
		<PaddedContainer flexContent centerContent {...rest}>
			<Text
				size={FONT_SIZE.BODY}
				color={"--disco-theme-mid"}
				clickable
				onClick={handleEditClick}
			>
				Edit
			</Text>
			<DropdownSelect
				value=""
				placeholder=""
				hideToggleIcon
				className="lead-offers-inline-view-offers-setting-dropdown"
				activator={renderActivator}
				options={options}
				renderCustomOptions={renderOptions}
			/>
		</PaddedContainer>
	);
};

const LeadOffersInlineViewOfferDetails = ({ offer }) => {
	const { heading, subheading, code, landing_page_url, disclosure } = offer;

	const { open, handleToggle } = useModal(false);
	const canShowMoreContent = landing_page_url || disclosure;

	const handleRedirect = () => {
		windowRedirect(landing_page_url);
	};

	return (
		<PaddedContainer>
			<Text size={FONT_SIZE.BODY} thick>
				{heading}{" "}
				{typeof subheading === "string"
					? subheading.toLowerCase()
					: subheading}
			</Text>
			{offer[LEAD_OFFER_TYPE_KEY] === LEAD_OFFER_TYPE.PROMO_CODE && (
				<Text size={FONT_SIZE.BODY} thick marginTop={SPACING.MICRO}>
					{code}
				</Text>
			)}
			{open && (
				<PaddedContainer
					marginTop={SPACING.TINY}
					motionElement
					variants={anim.variantFactory({
						y: -4,
						opacity: 0.5,
					})}
				>
					{disclosure && (
						<Text size={FONT_SIZE.BODY}>{disclosure}</Text>
					)}
					{disclosure && landing_page_url && (
						<PaddedContainer marginTop={SPACING.MICRO} />
					)}
					{landing_page_url && (
						<Text
							clickable
							onClick={handleRedirect}
							color="--disco-theme-mid"
							size={"body"}
						>
							{landing_page_url}
						</Text>
					)}
				</PaddedContainer>
			)}
			{canShowMoreContent && (
				<Text
					onClick={handleToggle}
					clickable
					style={{ textDecoration: "underline" }}
					color="mid"
					size={"label"}
					marginTop={SPACING.TINY}
				>
					See {open ? "Less" : "More"}
				</Text>
			)}
		</PaddedContainer>
	);
};
const LeadOffersInlineViewOffer = ({ offer, onEdit, onDelete, ...rest }) => {
	const [deleteError, setDeleteError] = useState(null);

	useTimer({
		tickSize: 1000,
		numTicks: 4,
		playing: !!deleteError,
		resetOnComplete: true,
		onComplete: () => setDeleteError(null),
	});

	return (
		<Card vPadding={SPACING.MEDIUM} hPadding={SPACING.MEDIUM} {...rest}>
			<PaddedContainer flexContent alignContentCenter>
				<LeadOffersInlineViewOfferDetails offer={offer} />
				<LeadOffersInlineViewOfferSettings
					offer={offer}
					marginLeft="auto"
					onEdit={onEdit}
					onDelete={onDelete}
					onDeleteError={setDeleteError}
				/>
			</PaddedContainer>
			{deleteError && (
				<StatusLabel
					type="error"
					noShadow
					noBorder
					marginTop={SPACING.TINY}
				>
					{parseError(deleteError)}
				</StatusLabel>
			)}
		</Card>
	);
};
export default LeadOffersInlineViewOffer;
