import React from "react";
import metaIcon from "./img/icons/meta.svg";
import snapIcon from "./img/icons/snapchat.svg";
import tiktokIcon from "./img/icons/tiktok.png";
import getPlatformPath from "./utils/getPlatformPath";

export const SHOPIFY_EMAIL_TYPES = {
	ORDER_CONFIRMATION: 0,
	SHIP_CONFIRMATION: 1,
	SHIP_UPDATE: 2,
	DELIVERY: 3,
};

export const CONTENTFUL_ACCESS_TOKEN =
		"zQDjHK8yecjXMsWIhAXlNhw9DrXVBuxeX5ryB-56NNQ",
	CONTENTFUL_SPACE = "uuaywa544byu";

//Extern URLs
export const EXTERNAL_KLAVIYO_TEMPLATES_URL =
	"https://www.klaviyo.com/email-templates/list";
export const EXTERNAL_KLAVIYO_KEYS_URL =
	"https://www.klaviyo.com/account#api-keys-tab";
export const EXTERNAL_SHOPIFY_EXTENSION_TRACKING_URL =
	"https://help.shopify.com/en/manual/promoting-marketing/analyze-marketing/pixel-tracking-post-purchase-page";
export const EXTERNAL_PLATFORM_HELP_URL =
	"https://discotechnology.zendesk.com/hc/en-us";

// Demo Video URLs
export const SHOPIFY_VIDEO_URL =
	"https://coopcommerce.s3.us-west-1.amazonaws.com/email-video-demos/shopify.mp4";
export const KLAVIYO_VIDEO_URL =
	"https://coopcommerce.s3.us-west-1.amazonaws.com/email-video-demos/klaviyo.mp4";

// Refresh token is it expires in < TOKEN_REFRESH_DELTA seconds
export const TOKEN_REFRESH_DELTA = 5;

export const SHOPIFY_CUSTOM_INTEGRATION_URl =
	"https://docs.google.com/forms/d/e/1FAIpQLScUKnW0dc-HbAqpsJjowKOtiwecu0Z9u4TMghFXqp3sy23Mfg/viewform";

export const SHOPIFY_LINKS = {
	[SHOPIFY_EMAIL_TYPES.ORDER_CONFIRMATION]: (user) =>
		`https://${user.shopify_shop}.myshopify.com/admin/email_templates/order_confirmation/edit`,
	[SHOPIFY_EMAIL_TYPES.SHIP_CONFIRMATION]: (user) =>
		`https://${user.shopify_shop}.myshopify.com/admin/email_templates/shipping_confirmation/edit`,
	[SHOPIFY_EMAIL_TYPES.SHIP_UPDATE]: (user) =>
		`https://${user.shopify_shop}.myshopify.com/admin/email_templates/shipping_update/edit`,
	[SHOPIFY_EMAIL_TYPES.DELIVERY]: (user) =>
		`https://${user.shopify_shop}.myshopify.com/admin/email_templates/local_out_for_delivery/edit`,
};

// For showing the special performance dashboard
export const REQUIRES_SPECIAL_DASH = [
	"c6745c92-6812-4d71-8c8b-eaffc8be6ef4", // Made In
	"58396e25-8401-4b6d-9dcc-0719ae7fb5f3", // Local test publisher id
];

export const BASE_DOMAIN =
	process.env.REACT_APP_ENV === "development"
		? "http://localhost:6543"
		: process.env.REACT_APP_ENV === "staging"
		? "https://merchant.disconetwork-staging.com"
		: "https://merchant.disconetwork.com";

export const API_PATH = "api";
// Base URL for all API endpoints
export const BASE_URL = `${BASE_DOMAIN}/${API_PATH}/`;

export const makeTestApiPath = (path) => `/${API_PATH}/${path}`;

export const PASSWORD_RESET_URL = BASE_URL + "accounts/password_reset/";
export const PASSWORD_RESET_CONFIRM_URL =
	BASE_URL + "accounts/password_reset_confirm/";

export const SAVE_LEADS_URL_PATH = "hubspot/record/create/";
export const SAVE_LEAD_URL = BASE_URL + SAVE_LEADS_URL_PATH;

export const IS_TEST_ENVIRONMENT =
	process.env.REACT_APP_ENV === "development" ||
	process.env.REACT_APP_ENV === "staging";

// Full URLs for non-loggedin URL
export const REFRESH_TOKEN_URL = BASE_URL + "accounts/refresh/";
export const LOGIN_URL = BASE_URL + "accounts/login/";
export const FETCH_STORE_URL = BASE_URL + "accounts/get_shops/";
export const SUPER_LOGIN_URL = BASE_URL + "accounts/super/";
export const REGISTER_URL = BASE_URL + "accounts/register/";

export const TOS_URL = "https://www.disconetwork.com/terms-of-service";
export const PRIVACY_POLICY_URL = "https://www.disconetwork.com/privacy-policy";
export const PRODUCT_PROCESSING_VIDEO_URL =
	"https://coopcommerce.s3-us-west-1.amazonaws.com/onboarding_assets/BoxesLoop.mp4";
export const INSTALL_VIDEO_URL =
	"https://coopcommerce.s3-us-west-1.amazonaws.com/install_demo.mov";
export const PP_EXTENSION_SETUP_VIDEO_URL =
	"https://coopcommerce.s3.us-west-1.amazonaws.com/pp-extension/pp-extension-setup.mov";

export const SHOPIFY_NO_VARIANT_CONTENT = (user, shopifyId) => (
	<>
		No variants for this product, add variants{" "}
		<a
			href={`https://${user.shopify_shop}.myshopify.com/admin/products/${shopifyId}/variants/new`}
			target="_blank"
			rel="noopener noreferrer"
		>
			via shopify
		</a>
	</>
);
// Partial URLs for logged in routes
export const FETCH_USER_URL = "accounts/user/";
export const FETCH_USER_OSP_STATUS = "shopify/osp_checkout_status/";

export const FETCH_CATEGORIES_URL = "core/categories/";
export const FETCH_CATEGORY_WHITELIST_URL = "core/category_whitelist/";
export const FETCH_BRANDS_URL = "core/brands/";
export const FETCH_COLLECTIONS_URL = "shopify/shopify_collections/";
export const FETCH_PRODUCTS_URL = "core/products/";
export const FETCH_SIMILAR_PRODUCTS_URL = "shopify/similar_products/";
export const SAVE_SIMILAR_PRODUCTS_URL = "shopify/merge_products/";
export const FETCH_DASHBOARD_URL = "accounts/analytics/";
export const FETCH_MODULE_ANALYTICS = "core/bulk_analytics/";
export const FETCH_SHOPIFY_PRODUCTS_URL = "core/shopify_products/";

export const FETCH_GOALS_URL = "accounts/brand_goals/";

export const FETCH_SHOPIFY_BILLING_HISTORY = "core/billing_history/";
export const FETCH_SHOPIFY_BILLING_HISTORY_CSV = "core/billing_history/csv/";

export const FETCH_SUB_CATEGORIES_URL = "core/subcategories/";
export const FETCH_SUB_CATEGORIES_DRAFT_URL = "core/subcategories/?edit=true";
export const FETCH_SPECIFIC_SUB_CATEGORIES_URL = "core/fetch_subcategories/";
export const SAVE_SUB_CATEGORIES_URL = "accounts/subcategories/";
export const SAVE_SHOP_CATEGORIES_URL = "core/update_preferences/";
export const SAVE_CATEGORY_WHITELIST_URL = "accounts/category_whitelist/";
export const SAVE_SUB_CATEGORY_BLACKLIST_URL =
	"accounts/subcategory_blacklist/";
export const FETCH_TAGGED_SUB_CATEGORIES_BLACKLIST_URL =
	"core/subcategory_blacklist_v2/";

export const FETCH_BLACKLISTED_BRANDS_URL =
	"core/feed_master_blacklisted_brands/";
export const FETCH_EMAIL_BLACKLISTED_BRANDS_URL =
	"core/email_master_blacklisted_brands/";

export const FETCH_CONFLICTING_VARIANTS_URL = "shopify/conflict_products/";
export const SAVE_CONFLICTING_VARIANTS_URL = "shopify/merge_variants/";

export const SAVE_PAYMENT_URL = "accounts/subscription/";
export const FETCH_CARD_SECRET = "accounts/card_setup/";
export const STRIPE_SECRET =
	process.env.REACT_APP_STRIPE_SECRET ||
	"pk_test_51HRYrTH3BmgfoppxHft69JWPCYycqrqqXR3YHpH60Cp0oqDdbIaMfkHyVme4j0y36swV7p6BDVapkMjsU6qhQXjK00t2Fs038t";

export const SHOPIFY_PAYMENT_URL = "shopify/billing/";

export const SUBSCRIPTION_ID =
	process.env.REACT_APP_SUBSCRIPTION_ID || "price_1HxKb8FPFns2ZQ4HGoEsQNdR";
export const SUBSCRIPTION_COST = process.env.REACT_APP_SUBSCRIPTION_COST;

export const FETCH_SUB_CATEGORY_BLACKLIST_URL = "core/subcategory_blacklist/";
export const SAVE_CATEGORIES_URL = "accounts/categories/";
export const PROFILE_IMAGE_UPLOAD_URL = "accounts/user/image/";
export const SAVE_BLACKLIST_URL = "accounts/blacklist/";

export const FETCH_BADGES_URL = "core/badges/";
export const SAVE_BADGES_URL = "accounts/badges/";

export const CHANGE_PASSWORD_URL = "accounts/password/";

export const SHOPIFY_CONNECT_URL = (shop) =>
	`shopify/connect_internal/?shop=${encodeURIComponent(shop)}`;
export const SHOPIFY_FINALIZE_URL = "shopify/finalize_internal/";
export const SHOPIFY_SYNC_URL = "shopify/sync/";
export const SHOPIFY_PERMISSION_URL = "shopify/connect_internal/";

export const SHOPIFY_EXTENSION_CONFIGURED_URL =
	"accounts/extension_configured/";
export const SHOPIFY_EXTENSION_OAUTH_PARAM = "EXTENSION_OAUTH_ERROR";
export const SHOPIFY_EXTENSION_PAYMENT_PARAM = "EXTENSION_PAYMENT_ERROR";

export const SHOPIFY_PERMISSION_TYPES = {
	EXTENSION_PERMISSION: "extension_permissions",
};

export const SETUP_BACK_URL = "accounts/setup/back/";
export const SETUP_NEXT_URL = "accounts/setup/next/";

export const MIGRATION_NEXT_URL = "accounts/migration/next/";
export const MIGRATION_BACK_URL = "accounts/migration/back/";

export const GET_NEXT_URL = (user) =>
	IN_ONBOARDING(user) ? SETUP_NEXT_URL : MIGRATION_NEXT_URL;

export const GET_BACK_URL = (user) =>
	IN_ONBOARDING(user) ? SETUP_BACK_URL : MIGRATION_BACK_URL;

export const SHOPIFY_SYNC_STATUS_URL = "shopify/sync_status/"; // Keeping for utility purposes

export const BRAND_INFO_URL = "accounts/brand_info/";
export const BRAND_INFO_URL_2 = "accounts/brand_info_v2/";

export const TOGGLE_UTM_URL = "accounts/brand_info/";
export const TOGGLE_UTM_OVERRIDE_URL = "accounts/brand_info/";

export const FETCH_UTM_URL = "core/list_utm_params/";
export const SAVE_UTM_URL = "core/utm_params/";

export const UTM_INSTRUCTIONS_URL =
	"https://support.disconetwork.com/hc/en-us/articles/14427265200155-TripleWhale-Integration-Setup";

export const TOGGLE_POST_URL = "core/brand_info/";

const BUCKET = process.env.REACT_APP_BUCKET || "coopcommerce-staging";

export const BASE_IMG_URL = `https://${BUCKET}.s3-us-west-1.amazonaws.com`;

const getExtension = (contentType) => {
	const parts = contentType.split("/");
	return `${parts[parts.length - 1]}` || "png";
};

export const UPLOAD_PRIVACY_HISTORICAL_DATA_CSV = "/identity/blacklist_users/";
export const DOWNLOAD_PRIVACY_HISTORICAL_SAMPLE_CSV = `${BASE_URL.substring(
	0,
	BASE_URL.length - 5
)}/static/sample_historical_sync.csv`;

const BASE_IMAGE_UPLOAD_URL =
	process.env.REACT_APP_IMAGE_UPLOAD ||
	"https://pb2oaz46oe.execute-api.us-west-1.amazonaws.com/uploads";

// Must be included in any image name - Used by the backend to identify image files
const UNIQUE_IDENTIFIER = "c13b-4c5c";

export const FETCH_IMAGE_DATA = (user, contentType) => {
	const name = `${
		user.publisher.remote_id
	}_${new Date().getTime()}_${UNIQUE_IDENTIFIER}.${getExtension(
		contentType
	)}`;

	return [
		`${BASE_IMAGE_UPLOAD_URL}?key=${encodeURI(
			name
		)}&content_type=${encodeURI(getExtension(contentType))}`,
		`${BASE_IMG_URL}/${name}`,
	];
};

export const MIN_SURVEY_OPTION = 1;

export const SETUP_STATUS_VERBOSE = [
	"Profile Setup",
	"Tool Setup",
	"Category Setup",
	"Payment Setup",
	"Privacy Disclaimer",
	"Setup Complete ",
	"",
	"",
];

export const SAVE_PUBLISHER_URL = "accounts/toggle_publisher/";
export const SAVE_NETWORK_URL = "accounts/toggle_network/";
export const SAVE_HEADER_URL = "accounts/header/";
export const SAVE_BRAND_URL = "accounts/brand_info/";
export const SAVE_LEADGEN_URL = "accounts/brand_info/";
export const SAVE_REWARD_TYPE_URL = "accounts/brand_info/";
export const SEND_REWARDS_EMAIL_URL = "core/send_rewards_email/";
export const SEND_MOBILE_TO_DESKTOP_ONBOARDING_EMAIL_URL =
	"accounts/mobile/registration_link/";
// export const SEND_REWARDS_EMAIL_URL = "accounts/brand_info/";
export const SAVE_NAME_URL = "accounts/name/";
export const SAVE_DESCRIPTION_URL = "accounts/single_liner/";
export const SAVE_LONG_DESCRIPTION_URL = "accounts/description/";
export const BANNER_IMAGE_UPLOAD_URL = "accounts/user/banner/";

// NEW
export const FETCH_THIRD_PARTY_TOOLS_URL = "core/third_party_tools/";

// Platform Authentications
export const FETCH_WOOCOMMERCE_OAUTH_URL = "woocommerce/oauth/";
export const FETCH_BIGCOMMERCE_OAUTH_URL = "bigcommerce/store_hash/";

export const TOGGLE_SURVEY_URL = "accounts/toggle_survey/";
export const TOGGLE_UPSELL_URL = "accounts/toggle_upsell/";
export const TOGGLE_PERMACART_URL = "accounts/brand_info/";
export const TOGGLE_POST_PURCHASE_URL = "accounts/toggle_post_purchase/";
export const TOGGLE_UPSELL_DISCOUNT_URL = "accounts/brand_info/";
export const TOGGLE_OFFER_URL = "accounts/toggle_offers/";
export const TOGGLE_CROSS_SELL_OFFER_URL = "accounts/toggle_cross_sell_offers/";
export const TOGGLE_REWARDS_URL = "accounts/brand_info/";

export const SAVE_LEAD_OFFERS_URL = (offer) =>
	`lead_gen/advertiser_offer/${
		offer?.remote_id ? `${offer.remote_id}/` : ""
	}`;
export const FETCH_LEAD_OFFERS_URL = "lead_gen/advertiser_offer/";

export const FETCH_BRAND_RECS_URL = "accounts/brand_recommendations/";

export const FETCH_SENT_INVITES_URL = "/accounts/member_requests/";
export const SEND_INVITE_URL = "/accounts/add_team_member/";
export const RESEND_INVITE_URL = (id) =>
	`/accounts/member_request/${id}/resend/`;
export const DELETE_INVITE_URL = (id) =>
	`/accounts/member_request/${id}/delete/`;

export const ACCEPT_INVITE_URL = "/accounts/add_user/";
export const FULL_ACCEPT_INVITE_URL =
	BASE_URL.substr(0, BASE_URL.length - 1) + "/accounts/add_user/";

export const FETCH_SURVEY_QUESTIONS_URL = "core/survey_questions/";
export const ADD_SURVEY_QUESTION_URL = "core/survey_question/";
export const SURVEY_IMAGE_UPLOAD_URL = "core/product/image/";
export const REORDER_SURVEY_URL = "core/survey_questions/order/";
export const SAVE_SURVEY_QUESTION_URL = (question) =>
	`core/survey_question/${question.remote_id}/`;
export const EDIT_SURVEY_OPTION_URL = (option) =>
	`core/survey_option/${option.remote_id}/`;

export const TOGGLE_EMAILS_URL = "shopify/emails/";
export const TOGGLE_EMAIL_UPSELL_URL = "shopify/emails/";
export const TOGGLE_EMAIL_CROSS_SELL_URL = "shopify/emails/";
export const SAVE_EMAIL_TEMPLATES_URL = "shopify/emails/";
export const FETCH_SHOPIFY_TEMPLATES_URL = "shopify/emails/templates/";
export const INITIATE_EMAIL_SETUP_URL = "core/initiate_emails/";
export const FETCH_EMAILS_PREVIEW_URL = (publisher) =>
	`core/email_preview/${publisher}/`;

export const FETCH_EMAILS_CATEGORY_WHITELIST_URL =
	"core/email_category_whitelist/";
export const FETCH_EMAILS_SUB_CATEGORY_BLACKLIST_URL =
	"core/email_subcategory_blacklist/";
export const FETCH_EMAILS_BRANDS = "core/email_brands/";

export const SAVE_EMAILS_CATEGORY_WHITELIST_URL =
	"accounts/email_category_whitelist/";
export const SAVE_EMAILS_SUB_CATEGORY_BLACKLIST_URL =
	"accounts/email_subcategory_blacklist/";
export const SAVE_EMAILS_BLACKLIST_URL = "accounts/email_blacklist/";

export const SAVE_KLAVIYO_API_URL = "accounts/brand_info/";
export const FETCH_KLAVIYO_OAUTH_INITIATE_URL =
	"/lead_gen/klaviyo_oauth_initiate/";
export const KLAVIYO_OAUTH_DISCONNECT_URL =
	"/lead_gen/klaviyo_oauth_disconnect/";
export const FETCH_KLAVIYO_TEMPLATE_URL = "core/klaviyo_block/";

export const FETCH_POSTS_URL = "core/custom_posts/";
export const SAVE_POST_URL = (post) => `core/custom_post/${post.remote_id}/`;
export const ADD_POST_URL = "core/custom_post/";

export const FETCH_FLOW_URL = "core/kanban_fetch/";
export const SAVE_FLOW_URL = (flow) => `core/kanban/${flow ? `${flow}/` : ""}`;
export const PUBLISH_FLOW_URL = `core/kanban_bulk/`;
export const FETCH_FLOWS_INFO_URL = "core/kanban_info/";

export const SAVE_COLOR_URL = "accounts/modal_color/";

export const SAVE_USER_URL = "accounts/info/";

export const FETCH_SURVEY_RESULT = "core/survey_responses/";
export const FETCH_SURVEY_CSV = "core/survey_responses/csv/";

export const OTHER_OPTION_IMG =
	"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_options/other.png";

export const SAVE_CODE_URL = "accounts/brand_info/";

export const FETCH_SHOPS_URL = "accounts/shops/";
export const SET_SHOP_URL = "accounts/switch_shop/";

export const PRODUCTS_CSV_ADD_URL = "core/products/csv/";
export const PRODUCTS_ADD_URL = "core/product/";
export const SAVE_PRODUCT_URL = (product) =>
	`core/product/${product.remote_id}/`;

export const SAVE_PRODUCT_ORDER_URL = "core/products/order/";

export const PRODUCT_UPDATE_UPSELL_URL = (product) =>
	`core/product/${product.remote_id}/`;

export const PRODUCT_BULK_UPDATE_URL = "core/products/bulk/ ";

export const PRODUCT_DELETE_URL = SAVE_PRODUCT_URL;
export const PRODUCT_IMAGE_UPLOAD_URL = "core/product/image/";

export const FETCH_WIDGET_MODULE_ANALYTICS = "/analysis/performance_overview/";

export const DEFAULT_OPTION_IMG =
	"https://coopcommerce.s3-us-west-1.amazonaws.com/option_color.png";

export const DEFAULT_USER_IMAGE =
	"https://coopcommerce.s3-us-west-1.amazonaws.com/user-default.png";

export const DEFAULT_BANNER_IMG =
	"https://coopcommerce.s3-us-west-1.amazonaws.com/default/banner.png";

export const DEFAULT_PRODUCT_IMG =
	"https://coopcommerce.s3-us-west-1.amazonaws.com/default/product.png";

export const DEFAULT_SHOP_IMG =
	"https://coopcommerce.s3-us-west-1.amazonaws.com/user_new.png";

export const PRODUCT_UPDATE_ACTIVE_URL = (product) =>
	`core/product/${product.remote_id}/`;

export const ONBOARDING_SETUP_STATUSES = {
	PLATFORM_SELECTION: "PLATFORM_SELECTION",
	REGISTRATION_SUCCESS: "REGISTRATION_SUCCESS",
	PROFILE_SETUP: "PROFILE_SETUP",
	CATEGORY_SETUP: "CATEGORY_SETUP",
	SETUP_COMPLETE: "SETUP_COMPLETE",
};

export const MIGRATION_SETUP_STATUSES = {
	UNINITIATED: "UNINITIATED",
	PRODUCT_SYNC: "PRODUCT_SYNC",
	PRODUCT_MERGE: "PRODUCT_MERGE",
	PRODUCT_SETUP: "PRODUCT_SETUP",
	PAYMENT_SETUP: "PAYMENT_SETUP",
	MIGRATION_COMPLETE: "MIGRATION_COMPLETE",
};

export const FINAL_SETUP_STATUS = ONBOARDING_SETUP_STATUSES.SETUP_COMPLETE;
export const MIGRATED_SETUP_STATUS =
	MIGRATION_SETUP_STATUSES.MIGRATION_COMPLETE;

export const IN_ONBOARDING = (user) =>
	user ? user.setup_status !== FINAL_SETUP_STATUS : false;

export const IN_TOS_MIGRATION = (user) =>
	user
		? user?.tos_beta &&
		  (!user?.publisher?.accepted_latest_privacy_tos_updates ||
				!user?.isNewIa)
		: false;

export const PREVIEW_TYPES_IDX = {
	FULL: "FULL",
	FLOW: "FLOW",
	CROSS_SELL: "CROSS_SELL",
	CROSS_SELL_PRODUCTS: "CROSS_SELL_PRODUCTS",
	UPSELL: "UPSELL",
	SELF: "SELF",
	SURVEY: "SURVEY",
	CUSTOM_POST: "CUSTOM_POST",
	REWARD: "REWARD",
	ALL_SURVEY: "ALL_SURVEY",
	ALL_CUSTOM_POST: "ALL_CUSTOM_POST",
	ALL_REWARD: "ALL_REWARD",
};

const BASE_WIDGET_URL = (publisher) =>
	`${
		process.env.REACT_APP_ENV === "development"
			? "http://localhost:3001?environment=development"
			: process.env.REACT_APP_ENV === "staging"
			? `https://launcher.disconetwork-staging.com/?environment=staging`
			: `https://launcher.disconetwork.com/?environment=${process.env.REACT_APP_ENV}`
	}&publisher=${publisher}`;

export const WIDGET_URL = ({
	publisher = "",
	type = "",
	previewID = false,
	live = false,
	showExtensionPreview = false,

	self = false,
	onboarding = false,
} = {}) =>
	`${BASE_WIDGET_URL(publisher)}&platform=true${
		self ? "&selfPreview=true" : ""
	}${live ? "&live=true" : ""}${type ? `&previewType=${type}` : ""}${
		previewID !== false ? `&previewID=${previewID}` : ""
	}${onboarding ? "&onboarding=true" : ""}${
		showExtensionPreview ? "&extensionPreview=true" : ""
	}`;

// in MB
export const MAX_IMG_SIZE = 15;
export const SHOPIFY_API_KEY = "baed076304d01ab44544d6984f60cbe2";

export const LOGOUT_URL =
	process.env.REACT_APP_ENV === "development" ? "/super" : "/";

export const AWS_IDENTIFIER = "c13b-4c5c";

export const SLACK_URL =
	"https://join.slack.com/t/theco-opcollective/shared_invite/zt-iex7595i-w3ZOp3NhJwsrin6ipyhAug";

const facebookRedirectUri =
	process.env.REACT_APP_ENV === "development"
		? "https://3527c8631882.ngrok.io/audiences"
		: process.env.REACT_APP_ENV === "staging"
		? "https://platform.disconetwork-staging.com/audiences"
		: "https://platform.disconetwork.com/audiences";
const facebookClientId =
	process.env.REACT_APP_FACEBOOK_APP_ID || "885722075514631";

export const AUDIENCES_NEXT_URL = "accounts/audience/next/";
export const AUDIENCES_BACK_URL = "accounts/audience/back/";
export const AUDIENCES_OPTED_IN = "accounts/brand_info/";
export const FACEBOOK_APP_URL = `https://www.facebook.com/v9.0/dialog/oauth?client_id=${facebookClientId}&redirect_uri=${facebookRedirectUri}&scope=ads_management,business_management,pages_read_engagement,pages_show_list&response_type=token`;
export const FACEBOOK_LOG_OUT_URL = "accounts/facebook_log_out/";
export const FETCH_AD_ACCOUNT_INFO_URL = "accounts/ad_account_info/";
export const FETCH_ALL_FACEBOOK_AD_ACCOUNTS_URL = "accounts/all_ad_accounts/";
export const SET_AD_ACCOUNT_URL = "accounts/update_ad_account/";
export const REQUEST_AUDIENCE_URL = "core/redemption_request/";

export const AUDIENCES_INIT_URL = "audience_retargeting/initiate_oauth/";

export const META_PRIVACY_URL = "https://disconetwork.com/terms-of-service";
export const AUDIENCES_PROVIDERS = { META: 0, SNAP: 2, TIKTOK: 3 };

export const META_OAUTH_URL = "accounts/facebook_oauth/";
export const SNAP_OAUTH_URL = "audience_retargeting/validate_snapchat_oauth/";
export const TIKTOK_OAUTH_URL = "audience_retargeting/validate_tiktok_oauth/";

export const N_AUDIENCES_FEATURES = 4;
export const SETUP_AUDIENCES_ACCOUNT_URL =
	"audience_retargeting/update_ad_account/";

export const FETCH_ALL_SNAP_AD_ACCOUNTS_URL =
	"audience_retargeting/snapchat_ad_accounts/";
export const FETCH_ALL_TIKTOK_AD_ACCOUNTS_URL =
	"audience_retargeting/tiktok_ad_accounts/";

export const AUDIENCES_PROVIDER_DATA = {
	[AUDIENCES_PROVIDERS.META]: {
		name: "Meta",
		testIdKey: "meta",
		connectionKey: "facebook_connected",
		accountKey: "facebook_ad_account_id",

		icon: metaIcon,

		initUrl: AUDIENCES_INIT_URL,
		oauthUrl: META_OAUTH_URL,
		fetchAdAccountsUrl: FETCH_AD_ACCOUNT_INFO_URL,
	},
	[AUDIENCES_PROVIDERS.SNAP]: {
		name: "Snapchat",
		testIdKey: "snap",
		connectionKey: "snapchat_connected",
		accountKey: "snapchat_ad_account_id",

		icon: snapIcon,

		initUrl: AUDIENCES_INIT_URL,
		oauthUrl: SNAP_OAUTH_URL,
		fetchAdAccountsUrl: FETCH_ALL_SNAP_AD_ACCOUNTS_URL,
	},
	[AUDIENCES_PROVIDERS.TIKTOK]: {
		name: "TikTok",
		testIdKey: "tiktok",
		connectionKey: "tiktok_connected",
		accountKey: "tiktok_ad_account_id",

		icon: tiktokIcon,

		initUrl: AUDIENCES_INIT_URL,
		oauthUrl: TIKTOK_OAUTH_URL,
		fetchAdAccountsUrl: FETCH_ALL_TIKTOK_AD_ACCOUNTS_URL,
	},
};

export const FETCH_AUDIENCES_HISTORY_URL = "audience_retargeting/history/";

export const AUDIENCES_SIZES = [
	{
		name: "Small audience",
		range: "1 - 8 million",
		text: "Reach the most focused group of relevant customers",
		count: 8000000,
		sizeGroup: 0,
	},
	{
		name: "Medium audience",
		range: "9 - 15 million",
		text: "Reach a mid-sized group with less focus",
		count: 16000000,
		sizeGroup: 1,
	},
	{
		name: "Large audience",
		range: "16 - 25 million",
		text: "Reach the broadest group of relevant customers",
		count: 25000000,
		sizeGroup: 2,
	},
	{
		name: "5K lookalike seed",
		range: "5,000",
		text: "Top 5,000 most relevant customers",
		count: 5000,
		sizeGroup: 3,
	},
	{
		name: "10K lookalike seed",
		range: "10,000",
		text: "Top 10,000 most relevant customers",
		count: 10000,
		sizeGroup: 4,
	},
	{
		name: "50K lookalike seed",
		range: "50,000",
		text: "Top 50,000 most relevant customers",
		count: 50000,
		sizeGroup: 5,
	},
	{
		name: "100K lookalike seed",
		range: "100,000",
		text: "Top 100,000 most relevant customers",
		count: 100000,
		sizeGroup: 6,
	},
];

export const TIKTOK_AUDIENCES_SIZES = [
	{
		name: "Small audience",
		range: "1 - 8 million",
		text: "Reach the most focused group of relevant customers",
		count: 8000000,
		sizeGroup: 0,
	},
	{
		name: "50K lookalike seed",
		range: "50,000",
		text: "Top 50,000 most relevant customers",
		count: 50000,
		sizeGroup: 5,
	},
	{
		name: "100K lookalike seed",
		range: "100,000",
		text: "Top 100,000 most relevant customers",
		count: 100000,
		sizeGroup: 6,
	},
];

export const FETCH_ADAPTIVE_OFFERS_INFO_URL = "core/adaptive_offers/";
export const UPDATE_ADAPTIVE_OFFERS_URL = "core/offer_configurations/";
export const SAVE_ADAPTIVE_OFFER_URL = (offer) =>
	`core/offer_configurations/${offer.remote_id}/`;

export const UPDATE_STRIPE_ACCOUNT_URL = "accounts/update_stripe_account/";
export const FETCH_STRIPE_ACCOUNT_DATA_URL =
	"accounts/stripe_account_metadata/";
export const ATTACH_ACCOUNT_TO_STRIPE_URL = "accounts/attach_account/";
export const FETCH_REQUESTS_INFO_URL =
	"audience_retargeting/lookalike_requests/";
export const REQUEST_LOOKALIKE_AUDIENCE_URL =
	"audience_retargeting/lookalike_request/";

export const TOS_OPT_IN_URL = "core/tos_pp_opt_in/";

export const LYDIA_CALENDLY_URL =
	"https://meetings.hubspot.com/lydia-bonakdarpour";
export const AARON_CALENDLY_URL =
	"https://calendly.com/aaroncronin/co-op-integration";

export const TEST_CALENDLY_URL = "https://calendly.com/acmesales";

export const REQUESTABLE_AUDIENCE_SIZE = {
	MIN: 100000,
	MAX: 500000,
	DEFAULT: 300000,
	STEP: 10000,
};

export const FEED_ONBOARDING_NEXT = "accounts/feed/next/";
export const FEED_ONBOARDING_BACK = "accounts/feed/back/";

export const FETCH_GROWTH_GRAPH_URL = "analysis/merchant_trends/";
export const FETCH_BRAND_STATS_URL = "analysis/merchant_stats/";

export const FETCH_NOTIFICATIONS_URL = "notifications/list/";

export const DEFAULT_ERROR_MESSAGE = `Whoops! We couldn't process your request right now. Please try again.`;
export const NAV_ERROR_MESSAGE = `Whoops! An error occurred while trying to navigate. Please try again.`;

export const DEFAULT_SURVEY = {
	text: "How did you hear about us?",
	options: [
		{
			text: "Facebook",
			icon_url:
				"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/fb.png",
			extra_text: "on",
			order: 1,
			active: true,
			remote_id: "fb-1",
		},
		{
			text: "Google",
			icon_url:
				"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/google.png",
			extra_text: "on",
			order: 2,
			active: true,
			remote_id: "gg-1",
		},
		{
			text: "Pinterest",
			icon_url:
				"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/pinterest.png",
			extra_text: "on",
			order: 4,
			active: true,
			remote_id: "pn-1",
		},
		{
			text: "Instagram",
			icon_url:
				"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/intsta.png",
			extra_text: "on",
			order: 3,
			active: true,
			remote_id: "ig-1",
		},
		{
			text: "TikTok",
			icon_url:
				"https://coopcommerce.s3-us-west-1.amazonaws.com/tiktok_icon+1.png",
			extra_text: "on",
			order: 5,
			active: true,
			remote_id: "tt-1",
		},
		{
			text: "Other",
			icon_url:
				"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_options/other.png",
			extra_text: "using",
			order: 10,
			active: true,
			remote_id: "ot-1",
		},
		{
			text: "Friends or Family",
			icon_url:
				"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/message.png",
			extra_text: "through",
			order: 7,
			active: true,
			remote_id: "ff-1",
		},
		{
			text: "Podcast",
			icon_url:
				"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/radio.png",
			extra_text: "by",
			order: 8,
			active: true,
			remote_id: "pd-1",
		},
		{
			text: "Magazine or Print",
			icon_url:
				"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/print.png",
			extra_text: "using",
			order: 9,
			active: true,
			remote_id: "mp-1",
		},
		{
			text: "Disco",
			icon_url:
				"https://coopcommerce.s3.us-west-1.amazonaws.com/inf-dark.png",
			extra_text: "on",
			order: 11,
			active: true,
			remote_id: "dc-1",
		},
	],
	rules: [],
	apply_and: false,
	apply_rules: false,
	randomize_options: false,
};

export const ICONS = [
	"https://coopcommerce.s3-us-west-1.amazonaws.com/option_color.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/fb.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/google.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/pinterest.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/intsta.png",
	"https://coopcommerce.s3.us-west-1.amazonaws.com/inf-dark.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_options/other.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/message.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/radio.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/survey_option_padding/print.png",
];

export const UTM_PARAMS = [
	"utm_source",
	"utm_medium",
	"utm_campaign",
	"utm_term",
	"utm_content",
];

export const PLATFORMS = {
	1: "Shopify",
	3: "WooCommerce",
};

export const MOBILE_VIEWPORT_WIDTH = 600;
export const LOGGED_OUT_USER = {
	manual_setup_status: "Logged out User",
	isSuper: false,
	publisher: {
		remote_id: "",
	},
};

export const DEFAULT_FLOWS = {
	new: "New Customers",
	returning: "Existing Customers",
};

export const FREE_ORDER_PER_MONTH = 100;

export const FREE_PLAN_FEATURES = [
	{
		text: "Show other merchants in your Disco",
	},
	{
		text: "Impressions on the Disco network",
		striked: true,
	},
	{
		text: "No upsells that boost AOV",
		striked: true,
	},
	{
		text: "No customer insights via surveys",
		striked: true,
	},
	{
		text: "No 2nd party audiences",
		striked: true,
	},
];

export const BASIC_PLAN_FEATURES = [
	{
		text: "Disco membership",
	},
	{
		text: "Cross-sell with merchants in your Disco",
	},
	{ text: "Slack community access" },
	{
		text: "Surveys to gather customer insights",
	},
	{
		text: "Upsells to increase AOV",
		striked: true,
	},
	{
		text: "Audiences to lower CPAs & CAC",
		striked: true,
	},
];

export const PRO_PLAN_FEATURES = [
	// {
	// 	text: "Everything in the Basic Plan",
	// },
	{
		text: "Disco membership",
	},
	{
		text: "Cross-sell with merchants in your Disco",
	},
	{ text: "Slack community access" },
	{
		text: "Surveys to gather customer insights",
	},
	{
		text: "Upsells to increase AOV",
	},
	{
		text: "Audiences to lower CPAs & CAC",
	},
];

export const IMS_OPTIONS = {
	Shopify: "Shopify",
	BigCommerce: "BigCommerce",
	Shippo: "Shippo",
	ShipStation: "ShipStation",
	EasyShip: "EasyShip",
	"Zoho Inventory": "Zoho Inventory",
	ShippingEasy: "ShippingEasy",
	Other: "Other",
};

export const TOOL_OPTIONS = {
	Shippo: "Shippo",
	ShipStation: "ShipStation",
	Aftership: "Aftership",
	Ohi: "Ohi",
	Malomo: "Malomo",
	ShipBob: "ShipBob",
	Netsuite: "Netsuite",
	Recharge: "Recharge",
};

export const STRIPE_PAY_OPTIONS = {
	HOLDER_TYPE: {
		individual: "Individual",
		company: "Company",
	},
	ACCOUNT_TYPE: {
		checking: "Checkings",
		savings: "Savings",
	},
};

export const DESIGNATIONS = {
	Marketing: "Marketing",
	Product: "Product",
	Design: "Design",
	Research: "Research",
	Engineering: "Engineering",
	Sales: "Sales",
};

export const CTA_LINK_MAPPING = (user) => ({
	CROSS_SELL: getPlatformPath("crossSell", user),
	SURVEY_SETUP: getPlatformPath("survey", user),
	AUDIENCES: getPlatformPath("audiences", user),
	REWARDS: getPlatformPath("disco-cash", user),
	UPSELL_EMAIL: getPlatformPath("emails-sell", user),
	CROSS_SELL_EMAIL: getPlatformPath("emails-grow", user),
	UPSELL_SETUP: getPlatformPath("upSell", user),
	PRODUCTS: getPlatformPath("products", user),
	PERFORMANCE_SUMMARY: getPlatformPath("summary", user),
	PERFORMANCE_PARTNERSHIPS: getPlatformPath("partnerships", user),
	PERFORMANCE_EMAILS: getPlatformPath("emailPerformance", user),
	PERFORMANCE_PREMIUM: getPlatformPath("premiumPerformance", user),
	BRAND_SETUP: getPlatformPath("brand", user),
	PAYMENT_SETUP: getPlatformPath("payment", user),
	TEAM_SETUP: getPlatformPath("team", user),
	PAGE_SETUP: getPlatformPath("pageSetup", user),
	ACCOUNT_SETUP: getPlatformPath("account", user),
	PRIVACY_SETUP: getPlatformPath("integrations", user),
});
export const UNIVERSAL_PREVIEW_HEIGHT = 740;
export const UNIVERSAL_PREVIEW_WIDTH = 330;

export const IA_MIGRATION_FINAL_STEP = 3;

export const DEFAULT_UNIVERSAL_PREVIEW_PROPS = {
	inFullScreen: false,
	type: "",
	customPreviewType: false,
	customPreviewID: false,
	customPreviewData: false,
	refresh: false,
	showFullPreview: false,
	disableNav: false,
};

export const BASE_TITLE = "Disco | Platform";

export const SUPPORT_EMAIL = "support@disconetwork.com";

export const SAMPLE_BRANDS = [
	{
		name: "Dreamland Baby",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/20210929170324/c13b-4c5c/ec2f195f-cd8f-49f8-8b3c-bed1850d6d8e/c13b-4c5c/9e4ae28e461c49b.png",
	},
	{
		name: "Little Sleepies",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/20211007080802/c13b-4c5c/6af322ac-d069-4d04-a67e-c60efa3913a9/c13b-4c5c/af7b802f04934fc.png",
	},
	{
		name: "Omsom",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/76350267-a66e-4016-988e-c4b0aed0c201_1626449912992_c13b-4c5c.png",
	},
	{
		name: "Girlfriend Collective",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/1173ec9d-919e-4ef8-8d64-5f8b365dfe5d_1639789268714_c13b-4c5c.jpeg",
	},
	{
		name: "Lovevery",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/20210816141930/c13b-4c5c/233ea9be-ff1f-4993-adce-bc17e829b8d4/c13b-4c5c/0061679d859240b.png",
	},
	{
		name: "Bobbie",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/8da5b951-41b3-4928-b31c-4d56dde2e3fb_1642637315566_c13b-4c5c.png",
	},
	{
		name: "Parade",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/20210720084040/c13b-4c5c/1c420a12-f877-4c1e-9a06-8a99a9e77af4/c13b-4c5c/dafe5ba951cc462.png",
	},
	{
		name: "Made In",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/c6745c92-6812-4d71-8c8b-eaffc8be6ef4_1612304909108_c13b-4c5c.jpeg",
	},
	{
		name: "ABLE",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/20211118105428/c13b-4c5c/a71e870b-801e-40d2-b893-e1dd745e1f8e/c13b-4c5c/2e6c6c4ed8ed4c0.png",
	},
	{
		name: "ezpz",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/20211014102825/c13b-4c5c/3c47e585-6904-4f0b-8a9f-47aa645f5842/c13b-4c5c/cb3e4eb9128f443.png",
	},
	{
		name: "Sunday Citizen",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/ca292f97-706a-4ac9-b4b2-8613fd3c986f_1619786332860_c13b-4c5c.png",
	},
	{
		name: "Kosas Cosmetics",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/1eb18a78-ebda-4a6a-8437-d0f94fe319b6_1640126411489_c13b-4c5c.png",
	},
	{
		name: "Mack Weldon",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/20210901120705/c13b-4c5c/b49851b2-9c52-40b4-ae11-49d3b4f88e63/c13b-4c5c/3f305a5cfebb45d.png",
	},
	{
		name: "Material",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/0331728f-5075-4967-bf2f-34fda7ba9b41_1622567647772_c13b-4c5c.png",
	},
	{
		name: "Porter Road",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/d889bac1-df4f-4429-bbd3-6aece9cb0265_1623700080979_c13b-4c5c.png",
	},
	{
		name: "Honest",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/20210812160825/c13b-4c5c/95a21c28-61c4-4d4f-9b4b-c64f556d362b/c13b-4c5c/15d65ede6e6e4d7.png",
	},
	{
		name: "Andie",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/27d7d886-a861-44c0-a679-42577b3631fa_1629915265280_c13b-4c5c.jpeg",
	},
	{
		name: "Kinder Beauty",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/20210625074817/c13b-4c5c/bb111c4d-e13e-4264-b711-b4491726f0a8/c13b-4c5c/5beb4cc39f23452.png",
	},
	{
		name: "Olive & June",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/3b17bcd7-a495-4fc5-a0a3-264bd923e024_1613074435736_c13b-4c5c.png",
	},
	{
		name: "Misen",
		logo_url:
			"https://coopcommerce.s3-us-west-1.amazonaws.com/20220118131325/c13b-4c5c/9201a3e8-bac7-455c-910a-4ce3acc5dfd2/c13b-4c5c/adf2795935794fc.png",
	},
];

export const SAMPLE_BRAND_IMAGES = [
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210929170324/c13b-4c5c/ec2f195f-cd8f-49f8-8b3c-bed1850d6d8e/c13b-4c5c/9e4ae28e461c49b.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20211007080802/c13b-4c5c/6af322ac-d069-4d04-a67e-c60efa3913a9/c13b-4c5c/af7b802f04934fc.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/76350267-a66e-4016-988e-c4b0aed0c201_1626449912992_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/1173ec9d-919e-4ef8-8d64-5f8b365dfe5d_1639789268714_c13b-4c5c.jpeg",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210816141930/c13b-4c5c/233ea9be-ff1f-4993-adce-bc17e829b8d4/c13b-4c5c/0061679d859240b.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/8da5b951-41b3-4928-b31c-4d56dde2e3fb_1642637315566_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210720084040/c13b-4c5c/1c420a12-f877-4c1e-9a06-8a99a9e77af4/c13b-4c5c/dafe5ba951cc462.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/c6745c92-6812-4d71-8c8b-eaffc8be6ef4_1612304909108_c13b-4c5c.jpeg",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20211118105428/c13b-4c5c/a71e870b-801e-40d2-b893-e1dd745e1f8e/c13b-4c5c/2e6c6c4ed8ed4c0.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20211014102825/c13b-4c5c/3c47e585-6904-4f0b-8a9f-47aa645f5842/c13b-4c5c/cb3e4eb9128f443.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/ca292f97-706a-4ac9-b4b2-8613fd3c986f_1619786332860_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/1eb18a78-ebda-4a6a-8437-d0f94fe319b6_1640126411489_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210901120705/c13b-4c5c/b49851b2-9c52-40b4-ae11-49d3b4f88e63/c13b-4c5c/3f305a5cfebb45d.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/0331728f-5075-4967-bf2f-34fda7ba9b41_1622567647772_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/d889bac1-df4f-4429-bbd3-6aece9cb0265_1623700080979_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210812160825/c13b-4c5c/95a21c28-61c4-4d4f-9b4b-c64f556d362b/c13b-4c5c/15d65ede6e6e4d7.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/27d7d886-a861-44c0-a679-42577b3631fa_1629915265280_c13b-4c5c.jpeg",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210625074817/c13b-4c5c/bb111c4d-e13e-4264-b711-b4491726f0a8/c13b-4c5c/5beb4cc39f23452.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/3b17bcd7-a495-4fc5-a0a3-264bd923e024_1613074435736_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20220118131325/c13b-4c5c/9201a3e8-bac7-455c-910a-4ce3acc5dfd2/c13b-4c5c/adf2795935794fc.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/3d5bbf3b-6f2f-467d-a4f1-02050515576e_1620069743046_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210920182841/c13b-4c5c/31382d47-efc3-4156-b02f-40b1cd73ebb0/c13b-4c5c/8e6884f4c356436.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20201207113510/f26f6861-96ad-41cb-9597-839078e23015/c13b-4c5c/74ecb780-d42c-49af-8b9d-0159b07c7603",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/b322d443-adad-4486-ac20-4127e5ccf755_1640300317712_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210928130707/c13b-4c5c/d6fb6a97-3945-45b0-ac6f-443b1a0707da/c13b-4c5c/73c6eb10514849e.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210611090209/c13b-4c5c/44a451e4-a640-496f-bd23-fddb1540f248/c13b-4c5c/994b864fa893464.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/a5fb910e-1a98-4e3f-8afa-4b764ce82c0d_1637599373212_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210601100429/c13b-4c5c/9448d496-c7da-4650-bacb-a4b1897553b6/c13b-4c5c/b10f0e8d788c42b.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/5af41e82-f7f9-4ca6-a2c8-61e368e0e470/c13b-4c5c/f1668c92-77cf-4a50-9b5c-d3a5760dee44",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/4adc7081-c05e-4522-9126-f7b871962af2_1635885427796_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/4be29e9d-15cd-41d8-831b-bafbd59603d6_1628106327891_c13b-4c5c.jpeg",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210707185112/c13b-4c5c/d5473819-f6eb-4248-a767-b2150adae137/c13b-4c5c/29bf65c1a4c0447.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210614133603/c13b-4c5c/90244175-bfec-4a95-bfa6-99ea5b2ed067/c13b-4c5c/ede6f7495cc647e.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20211018104916/c13b-4c5c/88a42684-2886-4d92-953b-0950522c58f0/c13b-4c5c/0829698e81c44e7.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/983320b9-a345-43bd-a251-d3a8df94c816/c13b-4c5c/ScreenShot2020-09-08at5_03_28PM.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20211104140625/c13b-4c5c/9afb6a60-9f4d-4820-8a43-1c4381d45dad/c13b-4c5c/8069030c020a484.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/cd178c2d-ed7e-45ef-9ad5-003d8687beea_1627569033334_c13b-4c5c.jpeg",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20211213085644/c13b-4c5c/827dde7b-4a4e-45ec-90c3-de92df466494/c13b-4c5c/14fa552f3bb84c5.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/8c6d2d03-ed56-4d9f-b215-bbf6fc7be23e_1618504258498_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20210813084137/c13b-4c5c/31bd54f0-7ea3-4758-acf4-2607ef05b846/c13b-4c5c/2f8bffd224cd4d0.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/2b1f6acf-69ac-440e-a7b7-1dd53974c667/c13b-4c5c/bf20974f-acb7-4a15-9358-b1c1058347a2",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/b3790daf-9c97-42cf-9c9e-0f47dec4158b/c13b-4c5c/47999f32-e4f4-4cfd-824c-391913521375",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/cd57f149-fd10-4cce-80db-193febe6b141_1632164408819_c13b-4c5c.jpeg",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/55a7a39d-5522-4008-b67d-7707607cb530_1623961625664_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/20220111102131/c13b-4c5c/f8ad9bda-fdbe-4adc-82c1-a4acb719fa89/c13b-4c5c/a840d43f6bd5400.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/82db8843-fee7-4ad0-80c4-bf6c8b09a7bb_1620367054552_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/d2a7642e-3a83-41a7-bc36-7ff4f5266c81_1640197721840_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/3dc4644d-8c44-418f-8991-1d8400fed0e0/c13b-4c5c/2a20dbef-91cf-4461-a38b-1856784fc607_1611241852864.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/91b18def-fbfd-4493-8100-63073f5c0d18_1639419165956_c13b-4c5c.png",
	"https://coopcommerce.s3-us-west-1.amazonaws.com/2744997e-a614-4e78-bb3f-8282abf55e0e_1619409801533_c13b-4c5c.jpeg",
];

export const PLATFORM_IDS = {
	shopify: 2,
	bigcommerce: 5,
	woocommerce: 4,
	other: 10,
};

// Google Analytics UA Tracking Code(s)
// Additional codes may be added in the future
export const GA_CODE =
	process.env.REACT_APP_ENV === "development"
		? "UA-221171210-1"
		: process.env.REACT_APP_ENV === "staging"
		? "UA-221171210-1"
		: "UA-221171210-1";

export const SHOPIFY_APP_STORE_URL = "https://apps.shopify.com/disco";
export const BIGCOMMERCE_APP_STORE_URL =
	"https://www.bigcommerce.com/apps/disco-growth-network/";

export const SHOPIFY_ADMIN_CHECKOUT_SETTINGS_URL = (
	{ shopifyShop = "" } = ""
) => `https://admin.shopify.com/store/${shopifyShop}/settings/checkout`;

export const LEAD_GEN_OFFER_CODE_HELP_ARTICLE_LINK =
	"https://support.disconetwork.com/hc/en-us/articles/21822196855963-Nurture-Offers";

export const LEAD_GEN_OFFER_KLAVIYO_HELP_ARTICLE_LINK =
	"https://support.disconetwork.com/hc/en-us/articles/25491489642523-Nurture-Klaviyo-Lead-Capture";
