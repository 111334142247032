import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { HiOutlineChevronDown as DownIcon } from "react-icons/hi";
import {
	Button,
	EmbeddedPreviewPage,
	FormStatus,
	IconButton,
	Input,
	Loader,
	Pagination,
	Tabs,
} from "../../components/CoreUI";
import {
	BRAND_INFO_URL,
	FETCH_COLLECTIONS_URL,
	FETCH_PRODUCTS_URL,
	PREVIEW_TYPES_IDX,
	SAVE_SIMILAR_PRODUCTS_URL,
} from "../../conf";
import useIdentity from "../../hooks/useIdentity";
import useModal from "../../hooks/useModal";
import useResource from "../../hooks/useResource";
import parseError from "../../utils/parseError";
import ProductsView from "../ProductsView";
import FloatingModal, { BELOW_RIGHT } from "../modals/FloatingModal";
import UTMModal from "../modals/UTMModal";
import "./products.css";
import { ImDrawer } from "react-icons/im";
import { MdRefresh } from "react-icons/md";
import usePreview from "../../hooks/usePreview";
import isShopifyShop from "../../utils/isShopifyShop";
import EditView from "../EditView";
import ProductsMerge from "../ProductsMerge";
import PermalinkModal from "../modals/PermalinkModal";
import ProductSidebarModal from "../modals/ProductSidebarModal";
import { GET } from "../../utils/GET";
import { SPACING, STATUS, StatusLabel } from "@disco/disco_core";

const ProductsHeader = memo(
	({
		hidePreview,
		handlePreviewOpen,
		numProducts,
		onTabChange,
		user,
		setUser,
		onboarding,
		reloadProducts,
	}) => {
		const [tabOptions, setTabOptions] = useState([
			...(isShopifyShop(user)
				? [
						{
							active: false,
							name: "By Collection",
							type: "collections",
						},
				  ]
				: []),
			{
				active: true,
				name: `All Products (${numProducts})`,
				type: "all",
			},
			...(isShopifyShop(user) && user.publisher?.has_unmatched_products
				? [
						{
							active: false,
							name: "Match Products",
							type: "match",
						},
				  ]
				: []),
		]);

		const settingsRef = useRef();

		const previewRef = useRef();

		const timerDuration = useRef(20000);

		const {
			open: UTMOpen,
			handleOpen: handleUTMOpen,
			handleClose: handleUTMClose,
		} = useModal();
		const {
			open: settingsOpen,
			handleOpen: handleSettingsOpen,
			handleClose: handleSettingsClose,
		} = useModal();

		const {
			open: permalinkModalOpen,

			handleClose: handlePermalinkModalClose,
		} = useModal();

		useEffect(() => {
			const utm = GET("UTM");

			if (!utm) {
				return;
			}

			handleUTMOpen();
		}, [handleUTMOpen]);

		useEffect(() => {
			const active = tabOptions.find((tab) => tab.active);
			onTabChange(active.type);
		}, [tabOptions, onTabChange, user]);

		const handleTabChange = useCallback((name) => {
			setTabOptions((tabs) =>
				tabs.map((tab) => ({ ...tab, active: tab.name === name }))
			);
		}, []);

		const utmRef = useRef();
		const permalinkRef = useRef();

		useEffect(() => {
			if (user.product_sync_complete) {
				const timer = setInterval(
					reloadProducts,

					timerDuration.current
				);
				return () => clearInterval(timer);
			}
		}, [user.product_sync_complete, reloadProducts]);

		return (
			<header className="products-header">
				<section className="products-header-half">
					<h2>Your Products</h2>
					<Tabs options={tabOptions} onChange={handleTabChange} />
				</section>
				<section className="products-header-half">
					{!onboarding && (
						<>
							{isShopifyShop(user) && (
								<IconButton
									onClick={handleSettingsOpen}
									className="products-header-minimal"
									icon={<DownIcon />}
									darkText
									ref={settingsRef}
								>
									General Settings
								</IconButton>
							)}
							<IconButton
								onClick={handleUTMOpen}
								ref={utmRef}
								className="products-header-minimal"
								icon={<DownIcon />}
								darkText
							>
								UTM Settings
							</IconButton>
							<IconButton
								onClick={() => {
									timerDuration.current = 5000;
									setUser((user) => ({
										...user,
										product_sync_complete: true,
									}));
								}}
								icon={
									user.product_sync_complete ? (
										<Loader small2 />
									) : (
										<MdRefresh />
									)
								}
								darkText
							></IconButton>

							{hidePreview && handlePreviewOpen && (
								<Button
									ref={previewRef}
									onClick={handlePreviewOpen}
								>
									Preview
								</Button>
							)}
						</>
					)}
				</section>
				{!onboarding && (
					<>
						<PermalinkModal
							open={permalinkModalOpen}
							onClose={handlePermalinkModalClose}
							position={BELOW_RIGHT}
							target={permalinkRef}
							user={user}
							setUser={setUser}
						/>
						<UTMModal
							open={UTMOpen}
							onClose={handleUTMClose}
							position={BELOW_RIGHT}
							target={utmRef}
							user={user}
							setUser={setUser}
						/>
						<FloatingModal
							open={settingsOpen}
							onClose={handleSettingsClose}
							heading="General Settings"
							target={settingsRef}
							position={BELOW_RIGHT}
						>
							<section className="products-settings-modal-section">
								<EditView
									user={user}
									setUser={setUser}
									url={BRAND_INFO_URL}
									remoteKey="exclude_out_of_stock"
									label="Exclude out-of-stock products"
									toggle
								/>
							</section>
						</FloatingModal>
					</>
				)}
			</header>
		);
	}
);

export const mergeProducts = ({ products, draft_products }) => {
	const done = new Set(),
		res = [];
	const process = (products) => {
		products.forEach((product) => {
			if (done.has(product.remote_id)) {
				return;
			}
			done.add(product.remote_id);
			res.push(product);
		});
	};
	if (draft_products) {
		process(draft_products, true);
	}
	if (products) {
		process(products);
	}
	return res;
};

const PAGE_SIZE = 60;

const CollectionsView = memo(
	({
		collections,
		activePage,
		onboarding,
		handleProductOpen,
		setRefreshPreview,
		products,
		setPreviewType,
		rowEvents,
		setProducts,
		onEdit,
		user,
		setUser,
	}) => {
		const timerRef = useRef(null);
		const [search, setSearch] = useState("");
		const [filterValue, setFilterValue] = useState("");
		const handleSearch = useCallback(({ target: { value } }) => {
			setSearch(value);
		}, []);

		const filter = (collection) => {
			const name = collection?.name?.toLowerCase();
			return name && name.indexOf(filterValue.toLowerCase()) !== -1;
		};

		useEffect(() => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
			const len = (Array.isArray(collections) && collections.length) || 0;
			console.log({ len });
			const delay = len > PAGE_SIZE - 10 ? 2000 : 500;
			timerRef.current = setTimeout(() => {
				setFilterValue(search);
			}, delay);

			return () => {
				const { current: timerRefCurrent } = timerRef;
				if (timerRefCurrent) {
					clearTimeout(timerRefCurrent);
				}
			};
		}, [search, collections]);

		return (
			<>
				<Input
					value={search}
					onChange={handleSearch}
					placeholder="Start typing to search..."
					className="products-collections-search"
				/>
				{collections
					.filter(filter)
					.map((collection, idx) =>
						idx >= activePage * PAGE_SIZE &&
						idx < (activePage + 1) * PAGE_SIZE ? (
							<ProductsView
								onboarding={onboarding}
								onProductOpen={handleProductOpen}
								setRefresh={setRefreshPreview}
								products={products}
								setPreviewType={setPreviewType}
								rowEvents={rowEvents}
								setProducts={setProducts}
								onEdit={onEdit}
								collection={collection}
								user={user}
								setUser={setUser}
								key={collection.shopify_collection_id}
							/>
						) : null
					)}
			</>
		);
	}
);

const Products = memo(({ onboarding = false, onEdit, setPreviewType }) => {
	const { user, setUser } = useIdentity();
	const [products, setProducts] = useState(null);
	const [collections, setCollections] = useState(null);
	const [productEditOpen, setProductEditOpen] = useState(false);
	const [tabType, setTabType] = useState(!isShopifyShop(user));
	const [mergeSave, setMergeSave] = useState(false);
	const [mergeFormError, setMergeFormError] = useState(false);
	const [mergePayload, setMergePayload] = useState();

	const {
		triggerRefresh: setRefreshPreview,
		setPreviewType: setUniversalPreviewType,
	} = usePreview();

	const [
		{ loading: mergeLoading, error: mergeError, data: mergeData },
		match,
		reset,
	] = useResource(
		{
			url: SAVE_SIMILAR_PRODUCTS_URL,
			method: "PUT",
			data: mergePayload,
		},
		false
	);

	const {
		open: productAddOpen,
		handleOpen: handleProductAddOpen,
		handleClose: handleProductAddClose,
	} = useModal(false);

	const [
		{
			data: collectionsData,
			loading: collectionsLoading,
			error: collectionsError,
		},
		,
		resetCollections,
	] = useResource({
		url: FETCH_COLLECTIONS_URL,
		method: "GET",
	});

	const [pages, setPages] = useState([]);

	const handlePageChange = useCallback((pageValue) => {
		setPages((pages) =>
			pages.map((page) => ({
				...page,
				active: page.page === pageValue,
			}))
		);
	}, []);

	useEffect(() => {
		if (!collectionsData) {
			return;
		}
		setCollections(collectionsData);

		setPages(() => {
			const pages = [];
			for (let i = 0; i < collectionsData.length / PAGE_SIZE; i++) {
				pages.push({ page: i + 1, active: i === 0 });
			}
			return pages;
		});

		resetCollections();
	}, [collectionsData, resetCollections]);

	useEffect(() => {
		setUniversalPreviewType(PREVIEW_TYPES_IDX.CROSS_SELL_PRODUCTS);
	}, [setUniversalPreviewType]);

	const [
		{ data: productsData, loading: productsLoading, error: productsError },
		reloadProducts,
		resetProducts,
	] = useResource({
		url: FETCH_PRODUCTS_URL,
		method: "GET",
	});

	useEffect(() => {
		if (!productsData) {
			return;
		}
		setProducts(
			mergeProducts(productsData).map((product) => ({
				...product,
				id: product.remote_id,
			}))
		);
		setUser((user) => ({
			...user,
			product_sync_complete: !productsData.product_sync_complete,
			shopify_syncing: !productsData.product_sync_complete,
		}));
		resetProducts();
	}, [productsData, resetProducts, user, setUser]);

	const isReady = Array.isArray(products) && Array.isArray(collections);

	const handleProductEditClose = useCallback(() => {
		setProductEditOpen(false);
	}, []);

	const rowEvents = useMemo(
		() => ({
			onClick: (e, product) => {
				setProductEditOpen(product.remote_id);
			},
		}),
		[]
	);

	const handleProductOpen = useCallback(
		(remoteId) => setProductEditOpen(remoteId),
		[]
	);

	const warn = useMemo(() => {
		if (!Array.isArray(products) || products.length < 4) {
			return false;
		}
		let activeOn = 0;

		products.forEach(({ upsell, active, variants }) => {
			const activeVariants = variants.reduce(
				(active, variant) =>
					variant.widget_active ? active + 1 : active,
				0
			);

			if (active) {
				activeOn += activeVariants + 1;
			}
		});

		if (activeOn < 4) {
			return onboarding
				? "Please select at least 4 products"
				: `Please enable cross-sell for at least 4 products`;
		}

		return false;
	}, [products, onboarding]);

	const activePage = pages.findIndex((page) => page.active);

	const renderHeader = useCallback(
		(props) => (
			<ProductsHeader
				{...props}
				numProducts={products ? products.length : -1}
				onTabChange={setTabType}
				onboarding={onboarding}
				reloadProducts={reloadProducts}
			/>
		),
		[products, setTabType, onboarding, reloadProducts]
	);

	return (
		<section
			className={`products ${onboarding ? "products-onboarding" : ""}`}
		>
			{(productsLoading || collectionsLoading) && <Loader center />}
			{productsError ||
				(collectionsError && (
					<FormStatus>
						{parseError(productsError || collectionsError)}
					</FormStatus>
				))}

			{isReady ? (
				products.length <= 0 ? (
					<>
						<section className="products-empty">
							<span>
								<ImDrawer />
							</span>
							<h2>No products added</h2>
							<p>
								You can add products manually by clicking the
								button below.
							</p>
							<Button
								gradient
								large
								onClick={handleProductAddOpen}
							>
								Add Product
							</Button>
						</section>
					</>
				) : (
					<>
						<EmbeddedPreviewPage
							user={user}
							setUser={setUser}
							renderHeader={renderHeader}
							showCrossSellProducts
							disablePreview={onboarding}
						>
							{/* <section className="products-children-container"> */}
							{/* <ProductsHeader
							user={user}
							setUser={setUser}
							numProducts={products ? products.length : -1}
							onTabChange={setTabType}
							onboarding={onboarding}
							reloadProducts={reloadProducts}
						/> */}
							{warn && (
								<StatusLabel
									type={STATUS.WARNING}
									centerContent
									noBorder
									noShadow
									vPadding={SPACING.REGULAR}
								>
									{warn}
								</StatusLabel>
							)}
							{tabType === "collections" && (
								<>
									<CollectionsView
										collections={collections}
										activePage={activePage}
										onboarding={onboarding}
										handleProductOpen={handleProductOpen}
										setRefreshPreview={setRefreshPreview}
										products={products}
										setPreviewType={setPreviewType}
										rowEvents={rowEvents}
										setProducts={setProducts}
										onEdit={onEdit}
										user={user}
										setUser={setUser}
									/>
									{pages.length > 1 && (
										<Pagination
											onPageChange={handlePageChange}
											pages={pages}
										/>
									)}
								</>
							)}
							{tabType === "all" && (
								<ProductsView
									products={products}
									onboarding={onboarding}
									setProducts={setProducts}
									setPreviewType={setPreviewType}
									collapsable={false}
									setRefresh={setRefreshPreview}
									onEdit={onEdit}
									rowEvents={rowEvents}
									user={user}
									setUser={setUser}
									initiallyExpanded={true}
									onAdd={handleProductAddOpen}
								/>
							)}
							{tabType === "match" && (
								<section className="products-merge-view">
									<ProductsMerge
										user={user}
										setUser={setUser}
										save={mergeSave}
										setSave={setMergeSave}
										formError={mergeFormError}
										setFormError={setMergeFormError}
										data={mergeData}
										reset={reset}
										match={match}
										setPayload={setMergePayload}
									/>
									{(mergeFormError || mergeError) && (
										<FormStatus className="products-merge-error">
											{mergeError
												? parseError(mergeError)
												: mergeFormError}
										</FormStatus>
									)}
									{mergeLoading ? (
										<Loader className="products-merge-save" />
									) : (
										<Button
											large
											light
											className="products-merge-save"
											onClick={() => setMergeSave(true)}
										>
											Save Merged Products
										</Button>
									)}
								</section>
							)}
							{/* </section> */}
						</EmbeddedPreviewPage>
					</>
				)
			) : null}
			<ProductSidebarModal
				onClose={handleProductAddClose}
				open={productAddOpen}
				products={products}
				setRefresh={setRefreshPreview}
				setProducts={setProducts}
				user={user}
			/>

			<ProductSidebarModal
				onClose={handleProductEditClose}
				open={!!productEditOpen}
				products={products}
				setProducts={setProducts}
				remoteId={productEditOpen}
				setRefresh={setRefreshPreview}
				user={user}
			/>
		</section>
	);
});

export default Products;
